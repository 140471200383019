import { makeStyles } from '@material-ui/core/styles';
import { CareerSiteSettings, ChatbotWidgetBrand } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { Description } from 'containers/Chatbot/ChatbotWidget/Components/Description';
import { SectionTitle } from 'containers/Chatbot/ChatbotWidget/Components/SectionTitle';
import { SettingTitle } from 'containers/Chatbot/ChatbotWidget/Components/SettingTitle';
import { BrandSelector } from 'containers/Chatbot/ChatbotWidget/GeneralSettings/BrandSelector';

import { SettingSwitch } from './Components/SettingSwitch';
import { messages } from './messages';
import { HandleSettingChange } from './types';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(4),
  },
  primaryTitle: {
    marginTop: theme.spacing(4),
  },
}));

export interface GeneralSettingsProps {
  brands: ChatbotWidgetBrand[];
  selectedBrandOption: ChatbotWidgetBrand;
  handleBrandChange: (brands: ChatbotWidgetBrand) => void;
  settings: CareerSiteSettings;
  handleSettingChange: HandleSettingChange;
}

export const GeneralSettings: VFC<GeneralSettingsProps> = ({
  brands,
  selectedBrandOption,
  handleBrandChange,
  settings,
  handleSettingChange,
}) => {
  const styles = useStyles();

  return (
    <section>
      <SectionTitle value={messages.generalSettings} />
      <SettingTitle value={messages.brandYourWidget} className={styles.title} />
      <Description value={messages.brandYourWidgetDesc} />
      <BrandSelector
        brands={brands}
        selectedBrand={selectedBrandOption}
        onChange={handleBrandChange}
      />
      <Description value={messages.additionaBrandInfo} />
      <SettingSwitch
        settingKey="display_compensation_type"
        label={messages.displayCompensationTypeSwitchLabel}
        checked={settings.display_compensation_type}
        onChange={handleSettingChange}
      />
      <Description value={messages.displayCompensationTypeSwitchDesc} />

      <SettingTitle
        value={messages.aiJobCategorization}
        className={styles.primaryTitle}
      />
      <SettingSwitch
        settingKey="categorize_jobs_by_job_type"
        label={messages.categorizationSwitchLabel}
        checked={settings.categorize_jobs_by_job_type}
        onChange={handleSettingChange}
      />
      <Description value={messages.categorizationSwitchDesc} />

      <SettingSwitch
        settingKey="categorize_jobs_by_experience_level"
        label={messages.experienceLevelSwitchLabel}
        checked={settings.categorize_jobs_by_experience_level}
        onChange={handleSettingChange}
        className={styles.title}
      />
      <Description value={messages.experienceLevelSwitchDesc} />
    </section>
  );
};
