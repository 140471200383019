import { CustomTooltip, Loader } from '@fountain/fountain-ui-components';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { ReloadableResultStatus } from 'api/resultStatus';
import { ApprovalObj, OpeningApprovals } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import SortingArrows from 'images/sorting-arrows';
import globalMessages from 'shared/global/messages';

import messages from './messages';
import { Status } from './Status';
import { useStyles } from './styles';

export interface ApprovalSort {
  key: string;
  value: string;
}

export interface ApprovalsTableProps {
  approvalsResult: ReloadableResultStatus<OpeningApprovals>;
  approvals: ApprovalObj[];
  selectedApprovals: string[];
  setSelectedApprovals: React.Dispatch<React.SetStateAction<string[]>>;
  sort: ApprovalSort;
  setSort: React.Dispatch<React.SetStateAction<ApprovalSort>>;
  handleAction: (approvalId: string, action: string) => void;
}

export const ApprovalsTable: VFC<ApprovalsTableProps> = ({
  approvalsResult,
  approvals,
  selectedApprovals,
  setSelectedApprovals,
  sort,
  setSort,
  handleAction,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const { accountSlug } = useParams<{ accountSlug: string }>();
  const isAllSelected = selectedApprovals.length === approvals.length;

  const togglePageSelect = () => {
    const selectAll = !isAllSelected;
    setSelectedApprovals(
      selectAll
        ? approvals
            ?.filter(
              approval =>
                approval.manage_access && approval.status === 'pending',
            )
            .map(approval => approval.id)
        : [],
    );
  };

  const isSelected = (approvallId: string) => {
    return !!selectedApprovals.includes(approvallId);
  };

  const handleSelect = (approvalId: string, value: boolean) => {
    const updatedApprovals = value
      ? selectedApprovals.filter(approval => approval !== approvalId)
      : [...selectedApprovals, approvalId];

    setSelectedApprovals(updatedApprovals);
  };

  const handleSort = (key: string) => {
    setSort({ key, value: sort.value === 'asc' ? 'desc' : 'asc' });
  };

  if (approvalsResult.isLoading) {
    return (
      <div className={styles.exceptionContainer}>
        <Loader block size="1.5rem" />
      </div>
    );
  }

  if (approvalsResult.status === 'ready') {
    if (approvals.length === 0) {
      return (
        <div className={styles.exceptionContainer}>
          <Typography>
            {intl.formatMessage(messages.noApprovalsFound)}
          </Typography>
        </div>
      );
    }

    return (
      <Table role="table" padding="none" className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow role="row" className={styles.tableRow}>
            <TableCell>
              <Grid item>
                <Checkbox
                  onChange={togglePageSelect}
                  checked={isAllSelected}
                  disabled={false}
                />
              </Grid>
            </TableCell>
            <TableCell role="cell">
              <div className={styles.headerCell}>
                <Typography variant="body2">
                  {intl.formatMessage(globalMessages.opening)}
                </Typography>
                <TableSortLabel
                  active={sort.key === 'opening'}
                  direction={sort.value === 'asc' ? 'desc' : 'asc'}
                  IconComponent={() => (
                    <SortingArrows
                      sortDirection={sort.value === 'asc' ? 'desc' : 'asc'}
                    />
                  )}
                  onClick={() => handleSort('opening')}
                ></TableSortLabel>
              </div>
            </TableCell>
            <TableCell role="cell">
              <div className={styles.headerCell}>
                <Typography variant="body2">
                  {intl.formatMessage(messages.approvalRule)}
                </Typography>
                <TableSortLabel
                  active={sort.key === 'approver'}
                  direction={sort.value === 'asc' ? 'desc' : 'asc'}
                  IconComponent={() => (
                    <SortingArrows
                      sortDirection={sort.value === 'asc' ? 'desc' : 'asc'}
                    />
                  )}
                  onClick={() => handleSort('approver')}
                ></TableSortLabel>
              </div>
            </TableCell>
            <TableCell role="cell">
              <Typography variant="body2">
                {intl.formatMessage(messages.approvalStatus)}
              </Typography>
            </TableCell>
            <TableCell role="cell">
              <Typography variant="body2">
                {intl.formatMessage(messages.approvalAction)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approvals.map(approval => (
            <TableRow key={approval.id} className={styles.tableRow}>
              <TableCell className={styles.tableCell}>
                <Grid item>
                  <Checkbox
                    checked={isSelected(approval.id)}
                    onClick={() =>
                      handleSelect(
                        approval.id,
                        selectedApprovals.includes(approval.id),
                      )
                    }
                    value={approval.id}
                    id={approval.id}
                    disabled={
                      !approval.manage_access || approval.status !== 'pending'
                    }
                  />
                </Grid>
              </TableCell>
              <TableCell
                className={styles.tableCell}
                component="th"
                scope="row"
              >
                <Box className={styles.cellContent}>
                  <FiberManualRecord
                    className={classNames(styles.activeIndicator, {
                      active: approval.funnel_approval_status === 'approved',
                      pendingApproval:
                        approval.funnel_approval_status !== 'approved',
                    })}
                  />
                  <Link
                    href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/openings/${approval.slug}`}
                    target="_blank"
                  >
                    {approval.title}
                  </Link>
                </Box>
              </TableCell>
              <TableCell className={styles.tableCell}>
                <Box className={styles.cellContent}>
                  <CustomTooltip
                    disableFocusListener
                    enterTouchDelay={100}
                    title={approval.approvers.join('\n')}
                    className={styles.toolTip}
                    dense
                    placement="top"
                  >
                    <Typography variant="caption">
                      <span className={styles.toolTip}>
                        {approval.approver_group_name}
                      </span>
                    </Typography>
                  </CustomTooltip>
                </Box>
              </TableCell>
              <TableCell className={styles.tableCell}>
                <Box className={styles.cellContent}>
                  <Status approval={approval} />
                </Box>
              </TableCell>
              <TableCell className={styles.tableCell}>
                <Button
                  className={classNames(
                    styles.actionButton,
                    styles.approveButton,
                  )}
                  variant={
                    approval.status === 'approved' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleAction(approval.id, 'approved')}
                  disabled={
                    !(approval.manage_access && approval.status !== 'approved')
                  }
                >
                  <Typography variant="caption">
                    {intl.formatMessage(messages.approve)}
                  </Typography>
                </Button>
                <Button
                  className={classNames(
                    styles.actionButton,
                    styles.rejectButton,
                  )}
                  variant={
                    approval.status === 'rejected' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleAction(approval.id, 'rejected')}
                  disabled={
                    !(approval.manage_access && approval.status === 'pending')
                  }
                >
                  <Typography variant="caption">
                    {intl.formatMessage(messages.reject)}
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return null;
};
