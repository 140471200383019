import { useSelector } from 'react-redux';

import { makeSelectPerms } from 'containers/Auth_old/selectors';
import { PERMISSION_MAPPING } from 'utils/constants';

enum UserPermissions {
  manageApplicantsPermission = 'MANAGE_APPLICANTS',
  addOrImportApplicantsPermission = 'ADD_OR_IMPORT_APPLICANTS',
  deleteApplicantsPermission = 'DELETE_APPLICANTS',
  sendMessagesFromUserPermission = 'SEND_MESSAGES_FROM_USER',
  viewSecureDataPermission = 'VIEW_SECURE_DATA',
  manageSecureDataPermission = 'MANAGE_SECURE_DATA',
  manageFunnelsPermission = 'MANAGE_FUNNELS',
  createFunnelsPermission = 'CREATE_FUNNELS',
  bulkEditFunnelsPermission = 'BULK_EDIT_FUNNELS',
  postToJobBoardsPermission = 'POST_TO_JOB_BOARDS',
  textToApplyPermission = 'TEXT_TO_APPLY',
  customReportsPermission = 'CUSTOM_REPORTS',
  manageHiringGoals = 'MANAGE_HIRING_GOALS',
  addNotesPermission = 'ADD_NOTES',
}
export default function useUserPermissions() {
  const permissions = useSelector(makeSelectPerms());

  const isAdmin = permissions.includes(PERMISSION_MAPPING.ADMIN);

  const getPermission = (permissionMappingKey: `${UserPermissions}`) =>
    isAdmin || permissions.includes(PERMISSION_MAPPING[permissionMappingKey]);

  const userPermissions: {
    [key in keyof typeof UserPermissions]?: boolean;
  } = Object.entries(UserPermissions).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: getPermission(v) }),
    {},
  );

  return {
    isAdmin,
    ...userPermissions,
  };
}
