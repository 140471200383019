import { Loader } from '@fountain/fountain-ui-components';
import React, { useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeIsCaiKnowledgeBaseV2Enabled } from 'containers/Auth_old/selectors';
import { retrieveKnowledgeBase } from 'containers/ChatAgent/api';
import { CONVERSATIONAL, UPLOAD } from 'containers/ChatAgent/constants';
import {
  AxAgentValues,
  KnowledgeBaseResponse,
} from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from '../../messages';
import KnowledgeBaseOptions from './KnowledgeBaseOptions';
import { KnowledgeBaseV1 } from './v1';
import { KnowledgeBaseV2 } from './v2';

export interface KnowledgeBaseProps {
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  accessToken: string;
  axAgentValues: AxAgentValues;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  knowledgeBaseOption: string;
  setKnowledgeBaseOption: React.Dispatch<React.SetStateAction<string>>;
  accountSlug: string;
}

export const KnowledgeBase: VFC<KnowledgeBaseProps> = ({
  setAxAgentValues,
  accessToken,
  axAgentValues,
  setIsLoading,
  isLoading,
  knowledgeBaseOption,
  setKnowledgeBaseOption,
  accountSlug,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const caiKnowledgeBaseV2Enabled = useSelector(
    makeIsCaiKnowledgeBaseV2Enabled(),
  );

  const [isKnowledgeBaseUploaded, setIsKnowledgeBaseUploaded] =
    useState<boolean>(false);
  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBaseResponse>();

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      setIsLoading(true);
      try {
        const response = await retrieveKnowledgeBase(
          accessToken,
          axAgentValues?.knowledgeBaseUuid as string,
        );
        setKnowledgeBase(response);
        if (response.version === 'v1' && response.uploads.length > 0) {
          setKnowledgeBaseOption(UPLOAD);
        } else if (response.version === 'v2') {
          setKnowledgeBaseOption(CONVERSATIONAL);
        }
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchKnowledgeBaseFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (axAgentValues.knowledgeBaseUuid) void fetchKnowledgeBase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, isKnowledgeBaseUploaded]);

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <>
      {!knowledgeBaseOption &&
        (caiKnowledgeBaseV2Enabled ? (
          <KnowledgeBaseOptions
            setKnowledgeBaseOption={setKnowledgeBaseOption}
            axAgentValues={axAgentValues}
          />
        ) : (
          <KnowledgeBaseV1
            setAxAgentValues={setAxAgentValues}
            accessToken={accessToken}
            axAgentValues={axAgentValues}
            knowledgeBase={knowledgeBase}
            isKnowledgeBaseUploaded={isKnowledgeBaseUploaded}
            setIsKnowledgeBaseUploaded={setIsKnowledgeBaseUploaded}
            accountSlug={accountSlug}
          />
        ))}

      {knowledgeBaseOption === CONVERSATIONAL && (
        <KnowledgeBaseV2
          setAxAgentValues={setAxAgentValues}
          accessToken={accessToken}
          axAgentValues={axAgentValues}
          accountSlug={accountSlug}
        />
      )}

      {knowledgeBaseOption === UPLOAD && (
        <KnowledgeBaseV1
          setAxAgentValues={setAxAgentValues}
          accessToken={accessToken}
          axAgentValues={axAgentValues}
          knowledgeBase={knowledgeBase}
          isKnowledgeBaseUploaded={isKnowledgeBaseUploaded}
          setIsKnowledgeBaseUploaded={setIsKnowledgeBaseUploaded}
          accountSlug={accountSlug}
        />
      )}
    </>
  );
};

export default KnowledgeBase;
