/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentConnectionResponse } from '../models/AgentConnectionResponse';
import type { CreateAgentParams } from '../models/CreateAgentParams';
import type { GenerateThreadSignatureParams } from '../models/GenerateThreadSignatureParams';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatAgentService {

    /**
     * Retrieve Agent Access Token
     * Returns the agent access token and URLs
     * @param accountSlug Account Slug
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiAgentIntegrationsAgentFetchAccessToken(
        accountSlug: string,
    ): CancelablePromise<{
        access_token: string;
        chat_agent_connection_url: string;
        chat_agent_widget_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/agent_integrations/agent/fetch_access_token',
            query: {
                'account_slug': accountSlug,
            },
        });
    }

    /**
     * Create ATS Connection
     * @param accountSlug Account Slug
     * @param brandId Brand Id
     * @param enableInFountainPlatforms Enable agent in Fountain platform
     * @param smsToChatAgentEnabled Enable agent for sms conversation
     * @param id Agent Id
     * @param status Agent Status
     * @returns void
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgentPublishChatAgent(
        accountSlug: string,
        brandId?: string,
        enableInFountainPlatforms?: boolean,
        smsToChatAgentEnabled?: boolean,
        id?: string,
        status?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent/publish_chat_agent',
            query: {
                'account_slug': accountSlug,
                'brand_id': brandId,
                'enable_in_fountain_platforms': enableInFountainPlatforms,
                'sms_to_chat_agent_enabled': smsToChatAgentEnabled,
                'id': id,
                'status': status,
            },
        });
    }

    /**
     * Generate Thread Signature
     * @param accountSlug Account Slug
     * @param brandId Brand Id
     * @param requestBody Applicant
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgentThreadSignature(
        accountSlug: string,
        brandId: string,
        requestBody: GenerateThreadSignatureParams,
    ): CancelablePromise<{
        widget_uri: string;
        agent_id: string;
        thread_id: string;
        signature: string;
        expiration: string;
        tenant: string;
        server: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent/thread_signature',
            query: {
                'account_slug': accountSlug,
                'brand_id': brandId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate Thread Signature
     * @param accountSlug Account Slug
     * @param funnelId Funnel Id
     * @param applicantId Applicant Id
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgentApplicantThreadSignature(
        accountSlug: string,
        funnelId: string,
        applicantId: string,
    ): CancelablePromise<{
        widget_uri: string;
        agent_id: string;
        signature: string;
        expiration: string;
        tenant: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent/applicant_thread_signature',
            query: {
                'account_slug': accountSlug,
                'funnel_id': funnelId,
                'applicant_id': applicantId,
            },
        });
    }

    /**
     * Generate WX I9 Bot Thread Signature
     * @param accountSlug Account Slug
     * @param brandId Brand Id
     * @param requestBody Applicant
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgentWxI9BotThreadSignature(
        accountSlug: string,
        brandId: string,
        requestBody: GenerateThreadSignatureParams,
    ): CancelablePromise<{
        widget_uri: string;
        agent_id: string;
        thread_id: string;
        signature: string;
        expiration: string;
        tenant: string;
        server: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent/wx_i9_bot_thread_signature',
            query: {
                'account_slug': accountSlug,
                'brand_id': brandId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Agent
     * @param accountSlug Account Slug
     * @param id Agent ID
     * @returns void
     * @throws ApiError
     */
    public static deleteInternalApiAgentIntegrationsAgent(
        accountSlug: string,
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/agent_integrations/agent/{id}',
            path: {
                'id': id,
            },
            query: {
                'account_slug': accountSlug,
            },
        });
    }

    /**
     * Get Agent
     * @param accountSlug Account Slug
     * @param id Agent ID
     * @returns AgentConnectionResponse successful
     * @throws ApiError
     */
    public static getInternalApiAgentIntegrationsAgent(
        accountSlug: string,
        id: string,
    ): CancelablePromise<AgentConnectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/agent_integrations/agent/{id}',
            path: {
                'id': id,
            },
            query: {
                'account_slug': accountSlug,
            },
        });
    }

    /**
     * Update Agent
     * @param accountSlug Account Slug
     * @param id Agent ID
     * @param requestBody Agent Data
     * @param brandId Brand Id
     * @returns AgentConnectionResponse successful
     * @throws ApiError
     */
    public static putInternalApiAgentIntegrationsAgent(
        accountSlug: string,
        id: string,
        requestBody: {
            enable_in_fountain_platforms?: boolean;
            sms_to_chat_agent_enabled?: boolean;
        },
        brandId?: string,
    ): CancelablePromise<AgentConnectionResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/agent_integrations/agent/{id}',
            path: {
                'id': id,
            },
            query: {
                'account_slug': accountSlug,
                'brand_id': brandId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create Agent
     * @param accountSlug Account Slug
     * @param requestBody Agent
     * @returns any created
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgent(
        accountSlug: string,
        requestBody: CreateAgentParams,
    ): CancelablePromise<{
        agent_id: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent',
            query: {
                'account_slug': accountSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list agent data
     * Return chat agent data
     * @param accountSlug Account Slug
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiAgentIntegrationsAgent1(
        accountSlug: string,
    ): CancelablePromise<{
        sample_knowledge_base_file_url: string;
        knowledge_base_help_center_article_url: string;
        available_agents: Array<{
            agent_id: string;
            brand_name: string;
            has_active_agent: boolean;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/agent_integrations/agent',
            query: {
                'account_slug': accountSlug,
            },
        });
    }

    /**
     * Create Rx Agent
     * @param accountSlug Account Slug
     * @returns any created
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgentCreateRxAgent(
        accountSlug: string,
    ): CancelablePromise<{
        rx_agent_id: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent/create_rx_agent',
            query: {
                'account_slug': accountSlug,
            },
        });
    }

    /**
     * Create Rx Thread and Signature
     * @param accountSlug Account Slug
     * @param id Agent ID
     * @param rxAgentId Rx Agent ID
     * @param knowledgeBaseId Knowledge Base ID
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiAgentIntegrationsAgentCreateRxThreadAndSignature(
        accountSlug: string,
        id: string,
        rxAgentId: string,
        knowledgeBaseId: string,
    ): CancelablePromise<{
        thread_id: string;
        signature: string;
        expiration: string;
        tenant: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/agent_integrations/agent/create_rx_thread_and_signature',
            query: {
                'account_slug': accountSlug,
                'id': id,
                'rx_agent_id': rxAgentId,
                'knowledge_base_id': knowledgeBaseId,
            },
        });
    }

    /**
     * fetch user
     * Return user data
     * @param accountSlug Account Slug
     * @param userExternalId User external id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiAgentIntegrationsAgentUser(
        accountSlug: string,
        userExternalId: string,
    ): CancelablePromise<{
        user: User;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/agent_integrations/agent/user',
            query: {
                'account_slug': accountSlug,
                'user_external_id': userExternalId,
            },
        });
    }

}