import {
  Avatar,
  Button,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { messages } from 'containers/ChatAgent/messages';
import {
  AgentStatus,
  AxAgentResponse,
  AxAgentValues,
} from 'containers/ChatAgent/types';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';

import { fetchUser } from '../api';
import BotSVG from '../images/bot.svg';
import { DeleteAgentConfirmationModal } from './modals/DeleteAgentConfirmationModal';
import { StatusChangeConfirmationModal } from './modals/StatusChangeConfirmationModal';
import { useAgentListStyles } from './styles';

interface AgentListProps {
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  axAgentValues: AxAgentValues;
  setAgents: React.Dispatch<React.SetStateAction<AxAgentResponse[]>>;
  agents: AxAgentResponse[];
  accountSlug: string;
  accessToken: string;
}

const GreenCircleIcon = () => (
  <svg
    width="6"
    height="7"
    viewBox="0 0 6 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="3.5" r="3" fill="#009E52" />
  </svg>
);

export const AgentList: React.FC<AgentListProps> = ({
  setStartChatbotProgressor,
  setRefreshToken,
  setAxAgentValues,
  axAgentValues,
  setAgents,
  agents,
  accountSlug,
  accessToken,
}) => {
  const classes = useAgentListStyles();
  const locale = useSelector(makeSelectLocale());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAgent, setCurrentAgent] = useState<AxAgentResponse>();
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState(false);
  const [agentLastModifiedBy, setAgentLastModifiedBy] = useState<{
    [key: string]: string;
  }>({});

  const { name, external_id: userExternalId } = useSelector(makeSelectWhoami());
  const { availableAgents } = axAgentValues;

  const userWhoModifiedAgent = async (agent: AxAgentResponse) => {
    const agentModifiedBy = agent.lastModifiedBy || agent.createdBy;
    if (agentModifiedBy === userExternalId) {
      return name;
    }
    const response = await fetchUser(accountSlug, agentModifiedBy);
    return response.user.name;
  };

  useEffect(() => {
    const fetchModifiedByUsers = async () => {
      const modifiedByPromises = agents.map(async agent => {
        const modifiedBy = await userWhoModifiedAgent(agent);
        return { [agent.uuid]: modifiedBy };
      });

      const modifiedByResults = await Promise.all(modifiedByPromises);

      const newModifiedByMap = modifiedByResults.reduce((acc, result) => {
        return { ...acc, ...result };
      }, {});

      setAgentLastModifiedBy(newModifiedByMap);
    };

    void fetchModifiedByUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents]);

  const openWidget = (agentId: string) => {
    const chatAgentWidgetUrl = localStorage.getItem(
      'chatAgentWidgetUrl',
    ) as string;
    const widgetUrl = `${chatAgentWidgetUrl}/ax-agent/${agentId}/apply/chat?show=true`;
    window.open(widgetUrl, '_blank');
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    agent: AxAgentResponse,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentAgent(agent);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (agent: AxAgentResponse, hasActiveAgent: boolean) => {
    handleClose();
    setStartChatbotProgressor(true);
    setAxAgentValues(prev => ({
      ...prev,
      axAgentId: agent?.uuid,
      knowledgeBaseUuid: agent?.knowledgeBase?.uuid,
      hasActiveAgent,
    }));
  };

  const handleDelete = (agent: AxAgentResponse) => {
    setCurrentAgent(agent);
    setOpenDeleteConfirmDialog(true);
  };

  const handleUpdateStatus = (agent: AxAgentResponse) => {
    setCurrentAgent(agent);
    setOpenStatusChangeDialog(true);
  };

  const activeAgents = agents
    .filter(agent => !agent.isDeleted)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  const renderStatus = (status: AgentStatus) => {
    switch (status) {
      case 'active':
        return <GreenCircleIcon />;
      case 'draft':
        return <div className={classes.draftLabel}>Draft</div>;
      default:
        return null;
    }
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.candidateAiAgent} />
        </Typography>
        <span>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              setStartChatbotProgressor(true);
            }}
            className={classes.button}
          >
            <FormattedMessage {...messages.setupAiAgent} />
          </Button>
        </span>
      </Grid>
      {activeAgents.map(agent => {
        const matchingAvailableAgent = availableAgents.find(
          availableAgent => availableAgent.agent_id === agent.uuid,
        );
        return (
          <Card key={agent.uuid} className={classes.card}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={BotSVG} className={classes.avatar} />
              <div className={classes.details}>
                <div className={classes.cardTitle}>
                  <div className={classes.agentName}>
                    {renderStatus(agent.status)}
                    <Typography variant="h3">{agent.name}</Typography>
                  </div>
                  <Typography variant="body2">
                    {matchingAvailableAgent?.brand_name}
                  </Typography>
                </div>
                <Typography variant="body2" color="textSecondary">
                  {agent.lastModifiedBy ? 'Modified on' : 'Created on'}{' '}
                  {new Date(agent.updatedAt).toLocaleString(locale, {
                    dateStyle: 'medium',
                  })}{' '}
                  {agentLastModifiedBy[agent.uuid]
                    ? `by ${agentLastModifiedBy[agent.uuid]}`
                    : ''}
                </Typography>
              </div>
            </div>
            <div className={classes.actions}>
              <Button
                variant="text"
                color="primary"
                startIcon={<OpenInNewIcon />}
                className={classes.agentButton}
                onClick={() => openWidget(agent.uuid)}
              >
                <FormattedMessage {...messages.testAiAgent} />
              </Button>
              <div>
                <IconButton
                  onClick={event => handleClick(event, agent)}
                  className={
                    anchorEl && currentAgent?.uuid === agent.uuid
                      ? classes.iconButtonHighlighted
                      : classes.iconButton
                  }
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && currentAgent?.uuid === agent.uuid}
                  onClose={handleClose}
                  classes={{ paper: classes.customMenu }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleEdit(
                        agent,
                        matchingAvailableAgent?.has_active_agent as boolean,
                      );
                      handleClose();
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleUpdateStatus(agent);
                      handleClose();
                    }}
                    className={classes.menuItem}
                    disabled={matchingAvailableAgent?.has_active_agent}
                  >
                    {agent.status === 'draft' ? 'Publish' : 'Unpublish'}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete(agent);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Card>
        );
      })}
      <DeleteAgentConfirmationModal
        handleClose={handleClose}
        currentAgentId={currentAgent?.uuid}
        openConfirmDialog={openDeleteConfirmDialog}
        setOpenConfirmDialog={setOpenDeleteConfirmDialog}
        setRefreshToken={setRefreshToken}
        setAgents={setAgents}
        accountSlug={accountSlug}
      />

      <StatusChangeConfirmationModal
        handleClose={handleClose}
        agent={currentAgent}
        openConfirmDialog={openStatusChangeDialog}
        setOpenConfirmDialog={setOpenStatusChangeDialog}
        setRefreshToken={setRefreshToken}
        accountSlug={accountSlug}
        accessToken={accessToken}
      />
    </Grid>
  );
};
