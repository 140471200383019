import {
  AgentStatus,
  ApplyBotTypes,
  AxAgentValues,
  KnowledgeBaseResponse,
  Step,
} from './types';

export const AI_AGENT_SETUP = 'ai_agent_setup';
export const AI_RECOMMENDATIONS = 'ai_recommendations';
export const ALL_BRANDS = 'all_brands';
export const INDIVIDUAL_BRAND = 'individual_brand';
export const CONVERSATIONAL = 'conversational';
export const UPLOAD = 'upload';

interface BotOption {
  label: string;
  key: string;
  description: string;
}

export const languages = [
  { key: 'en', label: 'English (EN)' },
  { key: 'es', label: 'Spanish (ES)' },
  { key: 'fr', label: 'French (FR)' },
  { key: 'de', label: 'German (DE)' },
  { key: 'it', label: 'Italian (IT)' },
];

export const steps: Step[] = [
  { number: 1, label: 'Personalize AI Agent' },
  { number: 2, label: 'Set AI Agent Functionalities' },
  { number: 3, label: 'Train AI Agent' },
];

export const applyBots: BotOption[] = [
  {
    label: 'Show jobs by location',
    key: 'apply',
    description:
      'Recommended if more than 20 active Openings exist in the account.',
  },
  {
    label: 'Show all available jobs',
    key: 'apply_few',
    description:
      'Recommended if less than 20 active Openings exist in the account.',
  },
  {
    label: 'Show only one job',
    key: 'apply_one',
    description:
      'This option is disabled if more than one Opening is available in the account.',
  },
];

export const applyV2Bots: BotOption[] = [
  ...applyBots,
  {
    label: 'Show V2 Job Recommendations',
    key: 'apply_v2',
    description:
      'This option is disabled if apply v2 bot is not enabled for the account.',
  },
];

export const initialAxAgentValues: AxAgentValues = {
  chatbotName: '',
  contactInfo: '',
  knowledgeBaseUuid: '',
  logoUrl: '',
  primaryColor: '',
  brandName: '',
  brandId: null,
  phoneNumberFallback: '',
  emailFallback: '',
  websiteFallback: '',
  enableInFountainPlatforms: false,
  smsEnabled: false,
  brands: [],
  atsConnectionId: '',
  axAgentId: '',
  rxAgentId: '',
  sampleKnowledgeBaseFileUrl: '',
  knowledgeBaseHelpCenterArticleUrl: '',
  status: 'draft' as AgentStatus,
  totalChatAgents: 0,
  customWebsites: [],
  applyBotType: 'apply' as ApplyBotTypes,
  noOfActiveFunnelsVisibleToApplicant: 1,
  careerSiteUrl: '',
  isDuplicateSettingCannotApplyAgain: false,
  rxThreadId: '',
  rxThreadSignature: '',
  rxThreadExpiration: '',
  tenant: '',
  languageCode: 'en',
  knowledgeBase: {} as KnowledgeBaseResponse,
  isActiveAgentAvailable: false,
  availableAgents: [],
  hasActiveAgent: false,
};
export const sampleAgentNames = [
  'ChatMate',
  'AvaBot',
  'TalkEase',
  'VirtuaHelp',
  'InfoBuddy',
  'BotSavvy',
  'Chatify',
  'AIlexa',
  'QuickReply',
  'SmartAgent',
  'EchoBot',
  'Assistly',
  'Nexa',
  'ChatterBox',
  'CompanAI',
  'BrainyBot',
  'SpeakEasy',
  'NeoHelp',
  'LogicLine',
  'CyberPal',
  'TalkSpace',
  'AutoMate',
  'Helpster',
  'AllyBot',
  'Claribot',
  'NovaTalk',
  'IntelliBot',
  'PixelPal',
  'InfoSage',
  'MindBot',
  'QueryQuirk',
  'VoiceVibe',
  'LumiBot',
  'DialogueBot',
  'BotZilla',
  'AIrony',
  'BrightMind',
  'NanoBot',
  'CommuniBot',
  'WittyAI',
  'FriendlyBot',
  'MegaChat',
  'SonicTalk',
  'OrbitBot',
  'AgentSmart',
  'GalaxyBot',
  'EchoSphere',
  'NeoSpeak',
  'InfoLink',
  'AstroBot',
  'CircuitChat',
  'CoreAI',
  'HarmonyBot',
  'SparkBot',
  'LunaBot',
  'QueryBot',
  'HelixBot',
  'MindfulAI',
  'RapidReply',
  'CloudTalk',
  'BotLogic',
  'QuickTalk',
  'SyncBot',
  'Visionary',
  'InquireBot',
  'ByteBuddy',
  'OrbitAI',
  'ChatNova',
  'TalkGuide',
  'SolveBot',
  'EngageBot',
  'NovaAgent',
  'BrainLink',
  'PrimeBot',
  'CloudyAI',
  'CircuitPal',
  'TaskBot',
  'PixelChat',
  'IdeaBot',
  'OrbitLink',
  'InfoBridge',
  'HarmonyAI',
  'LogicBridge',
  'EchoHelp',
  'StellarBot',
  'LunarTalk',
  'RadiantBot',
  'DialogPro',
  'OmniBot',
  'ParlayAI',
  'BrightLink',
  'GlowBot',
  'IntelliHelp',
  'CosmicBot',
  'NexusAI',
  'RapidAI',
  'HeliosTalk',
  'ChatCube',
  'InfinityBot',
  'PrimeTalk',
];
