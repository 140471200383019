import { WorkflowReviewStage, WorkflowStageDetail } from 'api-clients/monolith';
import { useContext } from 'react';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { ReviewStageDataField } from './schemas';
function isWorkflowReviewStage(
  stage: WorkflowStageDetail,
): stage is WorkflowReviewStage {
  return stage.type === 'ReviewStage';
}

export const useWorkflowReviewStage = () => {
  const { stage, setStage } = useContext(StageContext);

  if (!isWorkflowReviewStage(stage)) {
    throw new Error(
      `useWorkflowReviewStage must be used in ReviewStage, but was used in stage: ${stage.type}`,
    );
  }

  // see ./schemas for more info/context on _destroy
  const activeDataEntries = stage.additional_info.review_questionnaire.filter(
    ({ _destroy }) => !_destroy,
  ) as unknown as Array<ReviewStageDataField>;
  return { stage, setStage, activeDataEntries };
};
