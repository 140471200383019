/*
 *
 * JobsStagesFilterPopup actions
 *
 */

import {
  APPLY_NON_SAVED_FILTER_INIT,
  CREATE_STAGE_FILTER_ERROR,
  CREATE_STAGE_FILTER_INIT,
  CREATE_STAGE_FILTER_SUCCESS,
  DELETE_STAGE_FILTER_ERROR,
  DELETE_STAGE_FILTER_INIT,
  DELETE_STAGE_FILTER_SUCCESS,
  FETCH_STAGE_FILTERS_ERROR,
  FETCH_STAGE_FILTERS_INIT,
  FETCH_STAGE_FILTERS_SUCCESS,
  FUNNELS_SEARCH_COMPLETE,
  SET_SELECTED_FILTER,
  UPDATE_FUNNELS_SEARCH,
  UPDATE_STAGE_FILTER_ERROR,
  UPDATE_STAGE_FILTER_INIT,
  UPDATE_STAGE_FILTER_SUCCESS,
} from './constants';

export function fetchStageFiltersAction() {
  return {
    type: FETCH_STAGE_FILTERS_INIT,
  };
}

export function fetchStageFiltersSuccessAction(stageFilters) {
  return {
    type: FETCH_STAGE_FILTERS_SUCCESS,
    stageFilters,
  };
}

export function fetchStageFiltersErrorAction(error) {
  return {
    type: FETCH_STAGE_FILTERS_ERROR,
    error,
  };
}

export function createStageFilterAction(title, stageIdsWithFunnel) {
  return {
    type: CREATE_STAGE_FILTER_INIT,
    title,
    stageIdsWithFunnel,
  };
}

export function createStageFilterSuccessAction(stageFilter) {
  return {
    type: CREATE_STAGE_FILTER_SUCCESS,
    stageFilter,
  };
}

export function createStageFilterErrorAction(error) {
  return {
    type: CREATE_STAGE_FILTER_ERROR,
    error,
  };
}

export function updateStageFilterAction(title, filterId, stageIdsWithFunnel) {
  return {
    type: UPDATE_STAGE_FILTER_INIT,
    title,
    filterId,
    stageIdsWithFunnel,
  };
}

export function updateStageFilterSuccessAction(stageFilter) {
  return {
    type: UPDATE_STAGE_FILTER_SUCCESS,
    stageFilter,
  };
}

export function updateStageFilterErrorAction(error) {
  return {
    type: UPDATE_STAGE_FILTER_ERROR,
    error,
  };
}

export function deleteStageFilterAction(filterId) {
  return {
    type: DELETE_STAGE_FILTER_INIT,
    filterId,
  };
}

export function deleteStageFilterSuccessAction(filterId, data) {
  return {
    type: DELETE_STAGE_FILTER_SUCCESS,
    filterId,
    data,
  };
}

export function deleteStageFilterErrorAction(error) {
  return {
    type: DELETE_STAGE_FILTER_ERROR,
    error,
  };
}

export function setSelectedFilterAction(selectedFilter) {
  return {
    type: SET_SELECTED_FILTER,
    selectedFilter,
  };
}

export function applyNonSavedFilterAction(stageIdsWithFunnel) {
  return {
    type: APPLY_NON_SAVED_FILTER_INIT,
    stageIdsWithFunnel,
  };
}

export function updateSearchTextAction(search, activeOnly) {
  return {
    type: UPDATE_FUNNELS_SEARCH,
    search,
    activeOnly,
  };
}

export function jobsSearchCompleteAction(jobsData) {
  return {
    type: FUNNELS_SEARCH_COMPLETE,
    jobsData,
  };
}
