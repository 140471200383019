import { WorkflowSchedulerStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SettingSwitch } from '../../../SettingSwitch';
import { messages } from '../messages';

type SchedulerStageGoEnabled = {
  stage: WorkflowSchedulerStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowSchedulerStage>>;
};

export const SchedulerStageGoEnabled: FC<SchedulerStageGoEnabled> = ({
  stage,
  setStage,
}) => {
  const onToggleGoEnabled = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.go_enabled = !draftStage.go_enabled;
      }),
    );
  };

  return (
    <SettingSwitch
      dataKey="go_enabled"
      checked={Boolean(
        stage.go_enabled || stage.additional_info.use_go_hosts_for_interviewers,
      )}
      disabled={Boolean(stage.additional_info.use_go_hosts_for_interviewers)}
      onChange={onToggleGoEnabled}
      label={<FormattedMessage {...messages.goEnabled} />}
    />
  );
};
