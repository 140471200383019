import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { CONVERSATIONAL, UPLOAD } from 'containers/ChatAgent/constants';
import { AxAgentValues } from 'containers/ChatAgent/types';

import AiStarIcon from '../../images/ai-star.svg';
import UploadIcon from '../../images/upload.svg';
import { messages } from '../../messages';
import { useKnowledgeBaseOptionsStyles } from './styles';

export interface KnowledgeBaseOptionsProps {
  setKnowledgeBaseOption: React.Dispatch<React.SetStateAction<string>>;
  axAgentValues: AxAgentValues;
}

export const KnowledgeBaseOptions: VFC<KnowledgeBaseOptionsProps> = ({
  setKnowledgeBaseOption,
  axAgentValues,
}) => {
  const classes = useKnowledgeBaseOptionsStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h2">
        <FormattedMessage {...messages.trainAiAgent} />
      </Typography>
      <CardContent className={classes.cardContentWrapper}>
        <Typography variant="caption">
          <FormattedMessage {...messages.chooseKbMethod} />
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            {...messages.chooseKbMethodDesc}
            values={{
              helpCenterLink: (chunks: React.ReactNode) => (
                <a
                  href={axAgentValues.knowledgeBaseHelpCenterArticleUrl ?? '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      </CardContent>
      <Card
        className={classes.customCard}
        onClick={() => setKnowledgeBaseOption(CONVERSATIONAL as string)}
      >
        <CardContent className={classes.cardContentWrapper}>
          <Typography className={classes.recommendedLabel}>
            <FormattedMessage {...messages.recommended} />
          </Typography>
          <Typography variant="subtitle2" className={classes.cardContentHeader}>
            <Avatar src={AiStarIcon} className={classes.aiIcon} />{' '}
            <FormattedMessage {...messages.createConversationallyKb} />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage {...messages.createConversationallyKbDesc} />
          </Typography>
        </CardContent>
        <IconButton>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Card>

      <Card
        className={classes.customCard}
        onClick={() => setKnowledgeBaseOption(UPLOAD as string)}
      >
        <CardContent className={classes.cardContentWrapper}>
          <Typography variant="subtitle2" className={classes.cardContentHeader}>
            <Avatar src={UploadIcon} className={classes.uploadIcon} />{' '}
            <FormattedMessage {...messages.uploadKbCsvFile} />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage {...messages.uploadKbCsvFileDesc} />
          </Typography>
        </CardContent>
        <IconButton>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Card>
    </Box>
  );
};

export default KnowledgeBaseOptions;
