import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useKnowledgeBaseStyles = makeStyles<Theme>(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    height: '100%',
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  },
}));

export const useListKnowledgeBaseStyles = makeStyles<Theme>(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    height: '40px',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    background: theme.palette.common.gray100,
    height: '64px',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '5px',
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    minWidth: '235px',
  },
  button: {
    fontSize: theme.typography.fontSize,
    gap: theme.spacing(1),
    '& span': {
      margin: 0,
      color: theme.palette.common.gray800,
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& > svg': {
      color: theme.palette.common.green400,
    },
  },
}));

export const useStateStyles = makeStyles<Theme>(() => ({
  container: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

export const useKnowledgeBaseOptionsStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '664px',
  },
  customCard: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '12px',
    boxShadow: 'none',
    border: theme.customBorders.gray200,
    cursor: 'pointer',
    position: 'relative',
  },
  cardContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    flex: 1,
    padding: '0',
    color: '#000000',
  },
  cardContentHeader: {
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  uploadIcon: {
    height: '24px',
    width: '24px',
  },
  aiIcon: {
    height: '16px',
    width: '16px',
  },
  recommendedLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: theme.palette.common.blue50,
    borderRadius: '8px',
    fontSize: '14px',
    backgroundColor: theme.palette.common.blue50,
    color: theme.palette.common.blue400,
  },
}));

export const useConversationalKnowledgeBaseStyles = makeStyles<Theme>(() => ({
  iframe: {
    border: 'none',
  },
}));
