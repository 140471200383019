/* eslint-disable no-underscore-dangle */
import { IconButton } from '@fountain/fountain-ui-components';
import { Chip, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import VpnKey from '@material-ui/icons/VpnKey';
import { DataFields } from 'api-clients/monolith';
import produce from 'immer';
import React from 'react';
import { useIntl } from 'react-intl';

import { useQuestionDrawerContextState } from './context';
import { useWorkflowReviewStage } from './hooks';
import { questionnaireDisplayMessages } from './messages';
import { DataField } from './schemas';
import { useQuestionnaireDisplayStyles } from './styles';

const { editButtonText, deleteButtonText } = questionnaireDisplayMessages;

interface QuestionnaireDisplayProps {
  dataField: DataField;
}

export const QuestionnaireDisplay = ({
  dataField,
}: QuestionnaireDisplayProps) => {
  const intl = useIntl();
  const classes = useQuestionnaireDisplayStyles();
  const { stage, setStage } = useWorkflowReviewStage();
  const { setEditQuestion } = useQuestionDrawerContextState();

  const handleDeleteQuestion = () => {
    setStage(
      produce(stage, draftStage => {
        // unfortunately the types at the monolith level arent discriminated unions
        // so we must alias here to ReviewStageDataField to account for the difference
        const idxToUpdate = stage.additional_info.review_questionnaire.indexOf(
          dataField as unknown as DataFields[number],
        );

        if (idxToUpdate < 0) return;

        draftStage.additional_info.review_questionnaire[idxToUpdate] = {
          ...dataField,
          _destroy: true,
        } as unknown as DataFields[number];
      }),
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.questionContainer}>
        <Typography color="textPrimary">
          <b>{dataField.question}</b>
        </Typography>
        <div className={classes.metaContainer}>
          <Typography className={classes.type}>{dataField.type}</Typography>
          <Chip
            classes={{
              root: classes['MuiChip-root'],
            }}
            icon={<VpnKey className={classes.vpnIcon} />}
            className={classes.keyChip}
            size="small"
            label={dataField.key}
          />
        </div>
      </div>
      <div className={classes.actionsContainer}>
        <IconButton
          size="small"
          aria-label={intl.formatMessage(editButtonText)}
          onClick={() => setEditQuestion(String(dataField.id ?? dataField._id))}
        >
          <Edit className={classes.actionsIcons} />
        </IconButton>
        <IconButton
          size="small"
          aria-label={intl.formatMessage(deleteButtonText)}
          onClick={handleDeleteQuestion}
        >
          <Delete className={classes.actionsIcons} />
        </IconButton>
      </div>
    </div>
  );
};
