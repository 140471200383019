export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const TRACKING_VISITOR_KEY = 'visitorTracking';
export const ONBOARDING_WIZARD_LOCAL_STORAGE_KEY = 'onboardingWizard';
export const AMAZON_SLUG = 'amazon-delivery-service-partner';
export const AMAZON_SLUG_OLD = 'amazon-dsp-network';
export const AMAZON_MIDDLE_MILE_SLUG = 'amazon-middle-mile';
export const AMAZON_INDIA_SLUG = 'amazon-india';
export const AMAZON_MEXICO_SLUG = 'amazon-mx-delivery-service-partner';
export const AMAZON_DRIVER_EXPRESS_SLUG = 'driver-express';
export const AMAZON_EU_AFP_SLUG = 'europe';
export const AMAZON_EU_DSP_SLUG = AMAZON_DRIVER_EXPRESS_SLUG;
export const AMAZON_NA_DSP_SLUG = AMAZON_SLUG;
export const AMAZON_NA_AFP_SLUG = AMAZON_MIDDLE_MILE_SLUG;
export const UPS_SLUG = 'ups';

// Single tenant Amazon India account is churned
// https://fountain.atlassian.net/browse/DC-527?focusedCommentId=119873
// eslint-disable-next-line no-unused-vars
export const isAmazon = (accountSlug, _countryCode) =>
  accountSlug === AMAZON_SLUG || accountSlug === AMAZON_SLUG_OLD;

export const isUPS = accountSlug => accountSlug === UPS_SLUG;

export const BULK_ACTION_TYPE = {
  MESSAGE: 'message',
  OTHER: 'other',
};

export const ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE = {
  [BULK_ACTION_TYPE.MESSAGE]: 1500,
  [BULK_ACTION_TYPE.OTHER]: 8625,
};

export const ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_SECOND_BY_TYPE = {
  [BULK_ACTION_TYPE.MESSAGE]:
    ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE.message / 60,
  [BULK_ACTION_TYPE.OTHER]:
    ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_MINUTE_BY_TYPE.other / 60,
};

// Rough estimate on whether a Bulk Action Processing time is over three seconds
export const isProcessTimeOverThreeSeconds = (type, applicantsCount) => {
  const numberOfApplicantsPerSecond =
    ESTIMATED_NUMBER_OF_APPLICANTS_PROCESSED_PER_SECOND_BY_TYPE[type];

  return Math.ceil(applicantsCount / numberOfApplicantsPerSecond) > 3;
};

export const isAmazonIndia = (accountSlug, countryCode) =>
  accountSlug === AMAZON_SLUG && countryCode === 'IN';

export const isAmazonMexico = accountSlug => accountSlug === AMAZON_MEXICO_SLUG;

export const isAmazonEu = accountSlug =>
  accountSlug === AMAZON_EU_AFP_SLUG ||
  accountSlug === AMAZON_DRIVER_EXPRESS_SLUG;

export const FIELD_TYPES = {
  INPUT: 'input',
  DROPDOWN: 'dropdown',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};

export const STATES = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const TEMP_FILTER_TITLE = 'temp';

export const PERMISSION_MAPPING = Object.freeze({
  ADMIN: 'admin',
  MANAGE_APPLICANTS: 'manage_applicants',
  ADD_OR_IMPORT_APPLICANTS: 'create_applicants',
  DELETE_APPLICANTS: 'delete_applicants',
  SEND_MESSAGES_FROM_USER: 'send_messages_from_user',
  SECURE_DATA: 'secure_data',
  VIEW_SECURE_DATA: 'view_secure_data',
  MANAGE_SECURE_DATA: 'manage_secure_data',
  MANAGE_FUNNELS: 'manage_funnels',
  CREATE_FUNNELS: 'create_funnels',
  BULK_EDIT_FUNNELS: 'bulk_edit_funnels',
  POST_TO_JOB_BOARDS: 'post_to_job_boards',
  TEXT_TO_APPLY: 'text_to_apply',
  CUSTOM_REPORTS: 'custom_reports',
  MANAGE_HIRING_GOALS: 'manage_hiring_goals',
  ADD_NOTES: 'add_notes',
});
