/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Requests Users
     * Returns users within the account to assign users to
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiUsersPackUserOptions(): CancelablePromise<Array<{
        external_id: string;
        name: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/users_pack/user_options',
        });
    }

    /**
     * Requests User
     * Returns user information
     * @param id user id
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiUsersPack(
        id: number,
    ): CancelablePromise<{
        external_id: string;
        name: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/users_pack/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List All Accessible Opening IDs
     * @param userId The User ID
     * @param xAccessToken
     * @returns any OK
     * @throws ApiError
     */
    public static getInternalApiWxUsersAccessibleOpenings(
        userId: string,
        xAccessToken: string,
    ): CancelablePromise<{
        accessible_funnels: Array<string>;
        /**
         * Determines whether the user has access to all funnels on account. When true, `accessible_funnels` will be empty.
         */
        funnels_admin: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/wx/users/{user_id}/accessible_openings',
            path: {
                'user_id': userId,
            },
            headers: {
                'X-ACCESS-TOKEN': xAccessToken,
            },
        });
    }

    /**
     * Creates a user
     * @param xAccessToken API Token
     * @param requestBody
     * @returns any user created
     * @throws ApiError
     */
    public static postInternalApiWxUsers(
        xAccessToken?: string,
        requestBody?: {
            name: string;
            email: string;
            wx_external_id: string;
            phone_number?: string;
            time_zone?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/wx/users',
            headers: {
                'X-ACCESS-TOKEN': xAccessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `invalid request`,
            },
        });
    }

    /**
     * Updates a user
     * @param wxExternalId User WX External ID
     * @param xAccessToken API Token
     * @param requestBody
     * @returns any user updated
     * @throws ApiError
     */
    public static putInternalApiWxUsers(
        wxExternalId: string,
        xAccessToken?: string,
        requestBody?: {
            name: string;
            email: string;
            wx_external_id: string;
            phone_number?: string;
            time_zone?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/wx/users/{wx_external_id}',
            path: {
                'wx_external_id': wxExternalId,
            },
            headers: {
                'X-ACCESS-TOKEN': xAccessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `user not found`,
                422: `invalid request`,
            },
        });
    }

    /**
     * Deletes a user
     * @param wxExternalId User WX External ID
     * @param xAccessToken API Token
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static deleteInternalApiWxUsers(
        wxExternalId: string,
        xAccessToken?: string,
        requestBody?: {
            name: string;
            email: string;
            wx_external_id: string;
            phone_number?: string;
            time_zone?: string;
        },
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/wx/users/{wx_external_id}',
            path: {
                'wx_external_id': wxExternalId,
            },
            headers: {
                'X-ACCESS-TOKEN': xAccessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `user not found`,
            },
        });
    }

}