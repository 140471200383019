import { ReviewStageDataFieldTypeEnum } from '../../schemas';
import { MultipleChoiceForm } from '../MultipleChoice/MultipleChoiceForm';

export const DATA_FIELD_TYPE_TO_FORM: Record<
  keyof typeof ReviewStageDataFieldTypeEnum,
  () => JSX.Element | null
> = {
  // text field is the same as the common question form which we
  // always render -- so need to set it here, if eventually the two
  // diverge and text field has more than the common we can make that
  // change easily by adding it below
  text_field: () => null,
  datepicker: () => null,
  radio: MultipleChoiceForm,
};
