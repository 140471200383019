import { Button } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OpeningApprovalsService } from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Pagination } from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import { makeSelectPolicies } from 'containers/Auth_old/selectors';
import { useApiService } from 'hooks/useApiService';
import { useQueryState } from 'hooks/useQueryState';

import { ApproverGroupsModal } from './ApproverGroupsModal';
import { ApproverGroupTable } from './ApproverGroupTable';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  actionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: {
    height: '32px',
    padding: theme.spacing(1, 3),
    width: 'fit-content',
  },
}));

export const ApproverGroups = () => {
  const styles = useStyles();
  const intl = useIntl();

  const policies = useSelector(makeSelectPolicies());
  const { manage_opening_approvers: manageOpeningApprovers } = policies;

  const [approverGroup, setApproverGroup] = useState('');
  const [searchApproverGroup, setSearchApproverGroup] = useState('');

  const [sort, setSort] = useQueryState('sort', 'asc');
  const [page, setPage] = useQueryState('page', 1);
  const [perPage, setPerPage] = useQueryState('perPage', 10);

  const [totalRows, setTotalRows] = useState(0);
  const lastPage = Math.ceil(totalRows / parseInt(perPage ?? '10', 10));

  const {
    showContent: showAddApproverGroupModal,
    on: setAddApproverGroupModalOpen,
    off: setAddApproverGroupModalClosed,
  } = useSimpleToggle();

  const { result, refetch: refetchApproverGroupData } = useApiService(
    useCallback(
      () =>
        OpeningApprovalsService.getInternalApiOpeningApprovalApproverGroups(
          parseInt(page, 10),
          parseInt(perPage, 10),
          searchApproverGroup,
          sort,
        ),
      [page, perPage, searchApproverGroup, sort],
    ),
  );

  useEffect(() => {
    if (result.status === 'ready') {
      setTotalRows(result.data.pagination.total_count ?? 0);
    }
  }, [result]);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApproverGroup(e.target.value);
  };

  const clearSearchText = () => {
    setApproverGroup('');
    setSearchApproverGroup('');
  };

  const filterApprovals = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || (e.target as HTMLInputElement).value === '')
      setSearchApproverGroup(approverGroup);
  };

  const handleAddApproverGroup = () => {
    setAddApproverGroupModalOpen();
  };

  return (
    <>
      <div className={styles.actionRow}>
        {manageOpeningApprovers && (
          <Button
            type="secondary"
            className={styles.button}
            onClick={handleAddApproverGroup}
            disabled={!manageOpeningApprovers}
          >
            <Typography variant="h4" color="primary">
              {intl.formatMessage(messages.createApprovalRule)}
            </Typography>
          </Button>
        )}

        <SearchInput
          /* @ts-expect-error this will go away when `SearchInput` is converted to typescript */
          value={approverGroup}
          onChange={onSearchInputChange}
          onClear={clearSearchText}
          placeholder="Search"
          onKeyUp={filterApprovals}
        />
      </div>

      <ApproverGroupTable
        result={result}
        refetchApproverGroupData={refetchApproverGroupData}
        searchString={searchApproverGroup}
        sort={sort}
        setSort={setSort}
      />

      {totalRows > parseInt(perPage, 10) && (
        <Pagination
          perPage={parseInt(perPage, 10)}
          currentPage={parseInt(page, 10)}
          lastPage={lastPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      )}

      {showAddApproverGroupModal && (
        <ApproverGroupsModal
          refreshApproversData={refetchApproverGroupData}
          isOpen={showAddApproverGroupModal}
          onClose={setAddApproverGroupModalClosed}
        />
      )}
    </>
  );
};
