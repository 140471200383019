import { Loader } from '@fountain/fountain-ui-components';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { ReloadableResultStatus } from 'api/resultStatus';
import { OpeningApproverGroups } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectPolicies } from 'containers/Auth_old/selectors';
import SortingArrows from 'images/sorting-arrows';

import { ErrorMessage } from './ErrorMessage';
import messages from './messages';
import { RowActions } from './RowActions';
import { useStyles } from './styles';
export interface ApproverGroupTableProps {
  result: ReloadableResultStatus<OpeningApproverGroups>;
  refetchApproverGroupData: () => void;
  searchString: string;
  sort: string;
  setSort: (value: string | null) => void;
}

export const ApproverGroupTable: VFC<ApproverGroupTableProps> = ({
  result,
  refetchApproverGroupData,
  searchString,
  sort,
  setSort,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const policies = useSelector(makeSelectPolicies());
  const { manage_opening_approvers: manageOpeningApprovers } = policies;

  const handleSort = () => {
    setSort(sort === 'asc' ? 'desc' : 'asc');
  };

  if (result.isError) {
    // TODO: Implement error state
  }

  if (result.isLoading) {
    return (
      <div className={styles.exceptionContainer}>
        <Loader block size="1.5rem" />
      </div>
    );
  }

  if (result.status === 'ready') {
    if (result.data.approver_groups.length === 0) {
      return (
        <div className={styles.exceptionContainer}>
          <ErrorMessage searchString={searchString} />
        </div>
      );
    }

    return (
      <Table role="table" padding="none" className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow role="row" className={styles.tableRow}>
            <TableCell role="cell">
              {intl.formatMessage(messages.approvalRule)}
              <TableSortLabel
                active
                direction={sort === 'asc' ? 'desc' : 'asc'}
                IconComponent={() => (
                  <SortingArrows
                    sortDirection={sort === 'asc' ? 'desc' : 'asc'}
                  />
                )}
                onClick={handleSort}
              ></TableSortLabel>
            </TableCell>
            <TableCell role="cell">Approvers</TableCell>
            {manageOpeningApprovers && (
              <>
                <TableCell role="cell"></TableCell>
                <TableCell role="cell"></TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {result.data.approver_groups.map(group => (
            <TableRow key={group.id} className={styles.tableRow}>
              <TableCell
                className={styles.tableCell}
                component="th"
                scope="row"
              >
                <Typography className={styles.cellContent}>
                  {group.name}
                </Typography>
              </TableCell>
              <TableCell className={styles.tableCell}>
                <Typography className={styles.cellContent}>
                  {group.users.join(', ')}
                </Typography>
              </TableCell>
              <RowActions
                manageOpeningApprovers={manageOpeningApprovers}
                approverGroupId={group.id}
                refetchApproverGroupData={refetchApproverGroupData}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return null;
};
